import React from "react"
import {  graphql } from "gatsby"
import style from "./services.module.css"
import styled from "./page.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/footer"

const business = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return ( 
    <>
  <Navbar/>
    <header>
      <div className={style.p}>
      <div className={style.heading}>"Let Prueba make sure you are getting value for money and the right product,the one you thought you were getting."</div>
      </div>
      </header>
      <div className={styled.content} >
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/> 
    </div>
    <Footer />
    </>
  
      
  )
}
export const query = graphql`
query business {
  markdownRemark(fileAbsolutePath: {regex: "/BusinessTesting.md/"}) {
    html
    frontmatter {
      title
    }
  }
}
`

export default business